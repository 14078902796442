import React from "react";
import {
  FaFacebook,
  FaInstagram,
  FaYoutube,
  //   FaLinkedin,
  FaGoogle,
  FaWhatsapp,
} from "react-icons/fa";
// import { FaXTwitter } from "react-icons/fa6";

function SocialMedias() {
  return (
    <div className="Social-Medias">
      <a
        className="Social-Medias-Link"
        href={"https://www.facebook.com/profile.php?id=61555995860118"}
        target="_blank"
        rel="noreferrer noopener"
      >
        <FaFacebook color={"blue"} />
      </a>
      <a
        className="Social-Medias-Link"
        href={
          "https://www.instagram.com/assirem_dev?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="
        }
        target="_blank"
        rel="noreferrer noopener"
      >
        <FaInstagram  color={"black"} />
      </a>

      <a
        className="Social-Medias-Link"
        href={"https://www.youtube.com/channel/UCupQG-H2rAeLDKFC2hHp0iA"}
        target="_blank"
        rel="noreferrer noopener"
      >
        <FaYoutube color={"red"} />
      </a>

      {/* <a
        className="Social-Medias-Link"
        href={"/OurWork"}
        target="_blank"
        rel="noreferrer noopener"
      >
        <FaXTwitter size={20} color={"black"} />
      </a>

      <a
        className="Social-Medias-Link"
        href={"/OurWork"}
        target="_blank"
        rel="noreferrer noopener"
      >
        <FaLinkedin size={20} color={"blue"} />
      </a> */}

      <a
        className="Social-Medias-Link"
        href={"/OurWork"}
        target="_blank"
        rel="noreferrer noopener"
      >
        <FaGoogle
          onClick={() => (window.location = "mailto:assirem.dev.pro@gmail.com")}
          color={"black"}
        />
      </a>

      <a className="Social-Medias-Link" href="tel:+33787655880">
        <FaWhatsapp  color={"green"} />
      </a>
    </div>
  );
}

export default SocialMedias;
