import React from "react";
import "../App.css";
import CompanyCard from "../Components/CompanyCard";

function TheyTrustedUs({ imgLink, companyName }) {
  return (
    <div>
      <h1>Ils nous ont fait confiance</h1>
      <div class="logos-container">
        <div class="logos-track">
          <CompanyCard imgLink={"logo-renault.jpg"} companyName="Renault" />
          <CompanyCard
            imgLink={"logo-bnbhotels.png"}
            companyName="B&B Hotels"
          />
          <CompanyCard imgLink={"logo-suez.png"} companyName="Suez" />
          <CompanyCard imgLink={"logo-thales.png"} companyName="Thalès" />
          <CompanyCard imgLink={"logo-engie.png"} companyName="Engie" />
          <CompanyCard imgLink={"logo-renault.jpg"} companyName="Renault" />
          <CompanyCard
            imgLink={"logo-bnbhotels.png"}
            companyName="B&B Hotels"
          />{" "}
          <CompanyCard imgLink={"logo-suez.png"} companyName="Suez" />
          <CompanyCard imgLink={"logo-thales.png"} companyName="Thalès" />
          <CompanyCard imgLink={"logo-engie.png"} companyName="Engie" />
        </div>
      </div>
    </div>
  );
}

export default TheyTrustedUs;
