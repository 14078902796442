import React from "react";
import "../App.css";

function CompanyCard({ imgLink, companyName }) {
  return (
    <div class="logo">
      <img src={imgLink} alt="logo-company"></img>
      {companyName}
    </div>
  );
}

export default CompanyCard;
